.main-page.home-page {
    >div {
        width: 100%;
        padding: 20px 0;
        margin-bottom: 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        h2 {
            width: 100%;
        }
        &.popup {
            padding: 0; 
            margin: -25px auto 30px;
        }
        &.content {
            >div {
                width: 320px;
                box-sizing: border-box;
                padding: 0 30px 30px;
                margin: 10px 5px;
            }
            &.main {
                background: url(../../images/main.jpg) no-repeat;
                background-size: cover;
                background-position: top center; 
                >div {
                    width: 100%;
                    padding: 0 30px 30px;
                    &.siteIntro {
                        color: #fff;
                        text-shadow: 2px 1px #000;
                        padding: 0 30px;
                    }
                }
                .buttons {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    .btn {
                        color: #000;
                        text-shadow: none;
                        &:hover {
                            color: #fff;
                        }
                    }
                    .button {
                        width: calc(33% - 10px);
                        min-width: 260px;
                        border: solid 1px #ccc;
                        border-radius: 3px;
                        box-sizing: border-box;
                        padding: 20px;
                        margin: 10px 5px;
                        text-align: center;
                        color: #dedede;
                        background-color: #2b4e72;
                        cursor: pointer;
                        &:hover, &.current {
                            background-color: #2b4e72;
                            color: #ffffff;
                        }
                    }
                }
            }
            &.business {
                background: url(../../images/business-bg.jpg) no-repeat;
                background-size: cover;
                background-position: top center; 
                >div {
                    width: 30%; 
                    background-color: rgba(255,255,255, 0.5);
                    font-size: 14px;
                    color: #000;
                    padding: 15px 20px; 
                    h3 {
                        font-size: 18px;
                    }
                    a {
                        color: #000;
                    }
                    @media screen and (max-width: 768px) {
                        width: 80%; 
                    }
                }
            } 
            &.services {
                padding: 0;
                >div {
                    width: 25%;
                    box-sizing: border-box;
                    padding: 0 5px;
                    margin: 0;
                    @media screen and (max-width: 768px) {
                        width: 50%; 
                        >img {
                            height: 200px;
                        }
                    }
                    >img {
                        width: 100%;
                        height: 120px;
                        object-fit: cover; 
                    }
                    a {
                        cursor: pointer;
                        color: #2b4e72;
                        text-decoration: underline;
                    }
                } 
            }   
        }
    }
}