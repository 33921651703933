.main-page.content-page {
    padding: 20px;
    h2 {
        width: 100%;
    }
    .about {
        img.feature-img {
            float: right;
            width: 45%;
            max-height: 320px;
            object-fit: cover;
            padding: 0 0 15px 15px;
            @media screen and (max-width: 768px) {
                float: none;
                width: 100%; 
                padding-left: 0;
            }
        }
        .aboutList {
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            >div {
                width: 32%;
                box-sizing: border-box; 
                background-color: rgba(255,255,255, 0.5);
                font-size: 14px;
                color: #000;
                padding: 15px 0px; 
                h3 {
                    font-size: 18px;
                }
                img {
                    width: 100%;
                    object-fit: cover;
                }
                @media screen and (max-width: 768px) {
                    width: 100%; 
                }
            }
        }
    }
    .service {
        padding: 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        >div {
            width: 49%;
            box-sizing: border-box;
            margin-bottom: 20px;
            @media screen and (max-width: 768px) {
                width: 100%; 
                >img {
                    height: 320px;
                }
            }
            >img {
                width: 100%;
                height: 260px;
                object-fit: cover; 
            }
        } 
    }  
}