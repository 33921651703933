.main-page.contact-page {
    padding: 20px;
    div.contact-info {
        display: flex;
        //justify-content: center;
        flex-wrap: wrap;
        padding: 10px 0;
        > div{
            padding: 10px 20px 10px 0;
        }
    }
}