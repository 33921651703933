.header {
    margin: 0 auto;
    width: 100%;
    max-width: 1024px;
    border-bottom: 2px solid #282c34;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    position: relative;
    .logo {
        height: 80px;
        order: 1;
        img.logo_img {
            height: 80px
        }
    }
    .navMenu {
        flex: 1;
        order: 2;
    }
    .account {
        font-weight: bold;
        white-space: nowrap;
        padding: 2px 5px;
        margin: 36px 10px 0;
        cursor: pointer;
        order: 3;
    }
    .bts-login{
        display: none;
        position: absolute;
        right: 5px;
        top: 100px;
        width: 280px;
        background-color: #fff;
        z-index: 1;
        &.loginOpen{
            display: block;
        }
    }
    @media screen and (max-width: 640px) {
        .logo {
            flex: 1;
        }
        .navMenu {
            flex: none;
            order: 3;
        }
        .account {
            cursor: pointer;
            order: 2;
            &:hover {
                color: #2b4e72
            }
        }
    }
  }