
.bts-app {
  margin: 0;
  width: 100%;
  min-height:100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-page{
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
  box-sizing: border-box;
  padding: 20px 0;
  flex: 1;
}

.footer {
  background-color: #2b4e72;
  padding: 20px 30px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer>div {
  padding: 5px 0;
}

.button {
  min-width: 120px;
  min-height: 35px;
  display: inline-block;
  border: solid 1px #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 7px 10px;
  margin: 5px;
  text-align: center;
  color: #dedede;
  background-color: #2b4e72;
  cursor: pointer;
  text-decoration: none;
  &:hover, &.current {
      background-color: #2b4e72;
      color: #ffffff;
  }
}
.link {
  cursor: pointer;
  text-decoration: underline;
  color: #2b4e72;
  white-space: nowrap;
  &:hover {
    color: rgb(43, 22, 236);
  }
}