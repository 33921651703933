form.btsForm {
    width: 100%;
}
.bts_form {
    border: solid 1px #000;
    border-radius: 3px;
    margin: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    background-color: transparent;
    font: 16px Tahoma, sans-serif;
    .intro {
        padding: 0 0 10px;
        width: 100%;
    }
    .Links {
        padding: 10px 0 0;
        a {
            text-decoration: underline;
            color: #2b4e72;
            font-weight: 600;
            &:hover {
                text-decoration: none; 
            }
        }
    }
    .bts-form-field {
        width: 100%;
        position: relative;
        margin: 10px 0;
        border: solid 1px gray;
        &.hidden {
            display: none;
        }
        >input, >textarea {
            width: 100%;
            height: 35px;
            background: rgba(255,255,255,0.8);
            font-size: 16px;
            box-sizing: border-box;
            padding:2px 12px; 
            border: none;
        }
        >textarea {
            height: 100px;
            padding: 10px;
        }
        >span {
            position: absolute;
            top: 5px;
            left: 5px;
            z-index: -1;
        }
        input:-internal-autofill-previewed, input:-internal-autofill-selected, textarea:-internal-autofill-previewed, textarea:-internal-autofill-selected, select:-internal-autofill-previewed, select:-internal-autofill-selected {
            background-color: transparent !important;
            background-image: none !important;
            color: rgb(0, 0, 0) !important;
        }
        &.error, &.success {
            box-sizing: border-box;
            padding: 10px 20px;
            border: none;
            color: #fff;
            background-color: #ff0000;
        }
        &.success {
            background-color: #ffff00;
        }
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        >div, input {
            font-size: 16px;
            line-height: 36px;
            padding: 0 15px;
            background-color: #ddd;
            border: 1px solid #ccc;
            border-radius: 4px;
            margin: 5px 0 0 15px;
            text-align: center;
            cursor: pointer;
            &:hover {
                background-color: #2b4e72;
                color: #ffffff;
            }
        }
    }

    &.tracking {
        display: flex;
        flex-wrap: wrap;
        border: solid 1px #fff;
        background-color: rgba(255,255,255,0.3);
        width: 100%;
        .intro {
            font-weight: bold;
        }
        .bts-form-field {
            width: calc( 100% - 242px );
        }
        .buttons {
            width: 240px;
            .btn {
                height: 38px;
                line-height: 38px;
                border-radius: 0;
                border: 1px solid #FFF;
                box-sizing: border-box;
                background-color: rgb(43, 22, 236); 
                color: #fff!important;
                margin-top: 2px;
                &:hover {
                    background-color: #2b4e72;
                }    
            }
        }

        @media screen and (max-width: 800px) {
            .bts-form-field,.buttons {
                width: 100%;
            } 
        }
        .tracking-result {
            width: 100%;
            margin: 15px 0 0;
            padding: 15px 0 0;
            border-top: 1px solid #000;
            font-weight: bold;
            display: flex;
            flex-wrap: wrap;
            >div {
                font-weight: bold;
                padding: 5px;
                >span {
                    font-weight: normal;
                    &.status {
                        display: inline-block;
                        padding: 2px 5px;
                        font-weight: bold;
                    }
                }
                &.title {
                    width: 100%;
                }
            }
        }
    }
}